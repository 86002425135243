

<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="title">
				General Settings
			</div>
		</div>
		<div style="padding: 25px 15px; background: #fff; margin-bottom: 10px; border: 1px solid #ddd; border-top: 0;">

			<div style="max-width: 1000px; margin: auto; ">
				<form @submit.prevent="saveSettings">

					<div class="form-group">
						<label>
							Center Name
							<input type="text" class="form-control" v-model="settings.name">
						</label>
					</div>
					<div class="form-group">
						<label>
							Contact Person
							<input type="text" class="form-control" v-model="settings.contact_person">
						</label>
					</div>
					<div class="form-group">
						<label>
							Phone
							<input type="text" class="form-control" v-model="settings.phone">
						</label>
					</div>
					<div class="form-group">
						<label>
							Address
							<input type="text" class="form-control" v-model="settings.address">
						</label>
					</div>
					<div class="form-group">
						<label>
							Password
							<input type="password" class="form-control" v-model="settings.password">
						</label>
					</div>



					<div class="form-group" v-if="headerImage">
						<img :src="headerImage" alt="" style="display: block; width: 100%;" />
					</div>
					<div class="form-group">
						<label>
							Report Header
							<input type="file" class="form-control" ref="header_image" accept="image/*">
						</label>
					</div>
					<div class="form-group">
						<label>
							<input type="checkbox" v-model="settings.show_report_header"> Show Report Header
						</label>
					</div>


					<div class="form-group" v-if="footerImage">
						<img :src="footerImage" alt="" style="display: block; width: 100%;" />
					</div>
					<div class="form-group">
						<label>
							Report Footer
							<input type="file" class="form-control" ref="footer_image" accept="image/*">
						</label>
					</div>
					<div class="form-group">
						<label>
							<input type="checkbox" v-model="settings.show_report_footer"> Show Report Footer
						</label>
					</div>


					<div class="form-group">
						<label>
							<input type="checkbox" v-model="settings.show_qr_code"> Show QR Code
						</label>
					</div>
					<div class="form-group">
						<label>
							<input type="checkbox" v-model="settings.show_disclaimer"> Show Disclaimer
						</label>
					</div>

					<div style="padding: 10px; margin-top: 10px; padding-bottom: 0;">
						<div>
							<div style="display: flex;">
								<div style="margin-right: 20px;">Report Margins: </div>
								<div style="margin-right: 20px; width: 170px;">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Top</span>
										</div>
										<input type="number" v-model="settings.report_margin_top" name="report_margin_top"
											class="form-control">
										<div class="input-group-append">
											<span class="input-group-text">px</span>
										</div>
									</div>
								</div>
								<div style="margin-right: 20px; width: 180px;">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Right</span>
										</div>
										<input type="number" v-model="settings.report_margin_right" name="report_margin_right"
											class="form-control">
										<div class="input-group-append">
											<span class="input-group-text">px</span>
										</div>
									</div>
								</div>
								<div style="margin-right: 20px; width: 200px;">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Bottom</span>
										</div>
										<input type="number" v-model="settings.report_margin_bottom" name="report_margin_bottom"
											class="form-control">
										<div class="input-group-append">
											<span class="input-group-text">px</span>
										</div>
									</div>
								</div>
								<div style="margin-right: 20px; width: 170px;">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Left</span>
										</div>
										<input type="number" v-model="settings.report_margin_left" name="report_margin_left"
											class="form-control">
										<div class="input-group-append">
											<span class="input-group-text">px</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group clearfix">
						<button type="submit" :disabled="saving" class="btn btn-primary pull-right">Save</button>
					</div>
				</form>
			</div>
		</div>

	</div>
</template>

<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast
	},
	data() {
		return {
			center: {},
			saving: false,
			settings: {
				name: '',
				contact_person: '',
				phone: '',
				address: '',
				password: '',


				header_image: null,
				show_report_header: true,
				show_report_footer: true,
				show_qr_code: true,
				show_disclaimer: true,
				report_margin_top: 0,
				report_margin_right: 0,
				report_margin_bottom: 0,
				report_margin_left: 0
			},
			uploadPercentage: 0,

			headerImage: '',
			footerImage: '',
		}
	},
	mounted() {
		this.loadCenter();
	},
	methods: {
		loadCenter() {
			this.get("center/load-center").then(res => {
				this.center = res;

				this.settings.name = res.name;
				this.settings.contact_person = res.contact_person;
				this.settings.phone = res.phone;
				this.settings.address = res.address;

				this.settings.show_report_header = res.show_report_header == 'y';
				this.settings.show_report_footer = res.show_report_footer == 'y';
				this.settings.show_qr_code = res.show_qr_code == 'y';
				this.settings.show_disclaimer = res.show_disclaimer == 'y';
				this.settings.report_margin_top = res.report_margin_top;
				this.settings.report_margin_right = res.report_margin_right;
				this.settings.report_margin_bottom = res.report_margin_bottom;
				this.settings.report_margin_left = res.report_margin_left;


				if (res.header_image) this.headerImage = window.apiOrigin + '/images/center/' + res.header_image;
				if (res.footer_image) this.footerImage = window.apiOrigin + '/images/center/' + res.footer_image;
			});
		},
		saveSettings() {
			if (this.saving) return;
			this.saving = true;

			if(this.settings.password && this.settings.password.length < 5){
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Password must be at least 5 characters long', life: 3000 });
				this.saving = false;
				return;
			}

			let data = {
				name: this.settings.name,
				contact_person: this.settings.contact_person,
				phone: this.settings.phone,
				address: this.settings.address,
				password: this.settings.password,
				show_report_header: this.settings.show_report_header ? 'y' : 'n',
				show_report_footer: this.settings.show_report_footer ? 'y' : 'n',
				show_qr_code: this.settings.show_qr_code ? 'y' : 'n',
				show_disclaimer: this.settings.show_disclaimer ? 'y' : 'n',
				report_margin_top: this.settings.report_margin_top,
				report_margin_right: this.settings.report_margin_right,
				report_margin_bottom: this.settings.report_margin_bottom,
				report_margin_left: this.settings.report_margin_left,
			};

			let header = this.$refs['header_image'].files[0];
			if (header) data['header_image'] = header;

			let footer = this.$refs['footer_image'].files[0];
			if (footer) data['footer_image'] = footer;

			this.upload("center/save-general-settings", data, (err, res) => {
				this.saving = false;
				this.loadCenter();

				if (res.success) {
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Settings saved successfully.', life: 3000 });
					window.localStorage.center_access_token = res.center;
				} else {
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Sorry something went worng, Please try again later.', life: 3000 });
				}
			}, p => {
				this.uploadPercentage = Math.round(p * 100);
			})

		}
	}
}
</script>
<style scoped>
label {
	display: block;
}
</style>
